import { Controller } from "@hotwired/stimulus";
import { loading } from '../../utils/_loading';
import { debounce } from '../../utils/_debounce';
import DragSelect from "dragselect";

export default class extends Controller {
  static targets = [
    'selectable'
  ]

  static values = { url: String }

  SELECTED_COLOUR = 'rgba(74, 215, 209, 0.5)';
  INITIAL_COLOUR  = 'initial';
  PENDING_BOOKING_ICON = 'ri-calendar-check-line';

  connect() {
    this.setActiveSlots();

    const ds = new DragSelect({
      selectables: this.selectableTargets,
      draggability: false,
    });

    ds.subscribe("DS:end", debounce((e) => {
      this.toggleAvailability(e.items);
    }, 250));
  }

  toggleAvailability(targets) {
    let form = new FormData();

    targets.forEach((target) => {
      const innerDiv     = target.querySelector('.inner');
      const merchantId = document.body.dataset.currentMerchant;
      innerDiv.classList.add('justify-content-center');

      innerDiv.innerHTML = loading('', 'sm');

      form.append('availability', JSON.stringify({ date: targets.map(i => i.dataset.id), merchant_id: merchantId}))
    });

    const csrfTokenMetaTag = document.querySelector('meta[name="csrf-token"]')
    const csrfToken        = csrfTokenMetaTag ? csrfTokenMetaTag.getAttribute('content') : null;

    fetch(this.urlValue, {
      method: "PATCH",
      body: form,
      headers: {
        'X-CSRF-Token': csrfToken
      },
      credentials: 'include'
    })
    .then(response => response.json())
    .then(result => {
      if (result.message === 'success') {
        result.data.items.forEach((updatedItem) => {
          const cell     = this.selectableTargets.find(i => i.dataset.id === Object.keys(updatedItem)[0])
          const innerDiv = cell.querySelector('.inner');
          let icon       = document.createElement('i');
          let statusEl   = document.createElement('span')

          icon.classList.add(this.PENDING_BOOKING_ICON);
          innerDiv.classList.remove('justify-content-center');

          statusEl.classList.add('fs-10');
          statusEl.classList.add('ms-2');
          statusEl.innerHTML = this.mapStatusToShortStatus(Object.values(updatedItem)[0].status);

          innerDiv.innerHTML = '&nbsp;';
          innerDiv.appendChild(icon);
          innerDiv.appendChild(statusEl);

          if (innerDiv.style.backgroundColor === this.SELECTED_COLOUR) {
            innerDiv.innerHTML = '&nbsp;';
            innerDiv.style.backgroundColor = this.INITIAL_COLOUR;
          } else {
            innerDiv.style.backgroundColor = this.SELECTED_COLOUR;
          }
        });
      }
    })
    .catch(console.error)
  }

  setActiveSlots(){
    this.selectableTargets.forEach(element => {
      this.paintCells(element);
    });
  }

  paintCells(element) {
    if(element.dataset.active === "true") {
      const inner = element.querySelector('.inner');
      let icon    = document.createElement('i');

      icon.classList.add(element.dataset.icon);

      let statusEl = document.createElement('span')
      statusEl.classList.add('fs-10');
      statusEl.classList.add('ms-2');
      statusEl.innerHTML = element.dataset.status;

      inner.style.backgroundColor = element.dataset.colour;
      inner.appendChild(icon);
      inner.appendChild(statusEl);
    }
  }

  mapStatusToShortStatus(status) {
    return {
      'AVAILABLE':   'avl',
      'UNCONFIRMED': 'unc',
      'BOOKED':      'bkd'
    }[status]
  }
}
